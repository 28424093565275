import { LineUp, LineUpItem, LineUpItemChangeLog, Manager, Saison, Team, TeamPlayer, UserSaisonAlloc } from "./Saison";
import { Tipper, Tipp } from "./Tipp";

export type MatchDay = {
    id:string;
    saisonId:string;
    number:number;
    state:MatchDayState;
}

export type MatchDayMatch = {
    id:string;
    matchDayId:string;
    matchDayTeamsId1:string;
    matchDayTeamsId2:string;
    matchDate:string;
}

export type MatchDayImage = {

    id:string;
    matchDayId:string;
    image:string;
    sequence:number;
    creationTime?:string;
}

export type Image = {
    imageId:string
    width:number
    height:number
    creationTime:string 
    base64:string
}

export type MatchDayTeam = {
    id:string;
    goalsConceded:number;
    goalsConcededOld:number;
    teamId:string;
    goalsConcededOldChangedOn:string;
    matchDayId:string;
}

export type MatchDayEvent = {
    id:string;
    date:string;
    lineUpItemIdAssist:string;
    lineUpItemIdCard:string;
    lineUpItemIdGoal:string;
    matchDayId:string;
    matchDayMatchId:string;
    matchDayTeamId:string;
    matchEventType:MatchEventType;
    matchDayTeam1Goals:number;
    matchDayTeam2Goals:number;
}

export type MatchDayModel = {
    saison:Saison;
    matchDay:MatchDay;
    previousMatchDay:MatchDay;
    nextMatchDay:MatchDay;
    matchDayImages:MatchDayImage[];
    matchDayMatches:MatchDayMatch[];
    matchDayTeams:MatchDayTeam[];
    matchDayEvents:MatchDayEvent[];
    teams:Team[];
    userSaisonAllocs:UserSaisonAlloc[];
    userSaisonAlloc:UserSaisonAlloc;
    lineUps:LineUp[]
    lineUpItems:LineUpItem[];
    teamPlayers:TeamPlayer[];
    tippers:Tipper[];
    tipps:Tipp[];
    managers:Manager[];
    images:Image[];
    serverDateTime:string
}

export type MatchDayMatchModel = {
    matchDay:MatchDay;
    matchDayMatch:MatchDayMatch;
    matchDayMatchTeams:MatchDayTeam[];
    matchDayMatchEvents:MatchDayEvent[];
    saison:Saison;
    teams:Team[];
    teamPlayers:TeamPlayer[];
    lineUpItems:LineUpItem[];
    lineUpItemsChangeLogs:LineUpItemChangeLog[];
    userSaisonAllocs:UserSaisonAlloc[];
    userSaisonAlloc:UserSaisonAlloc;
    serverDateTime:string
}

export type MatchDaysModel = {
    matchDays:MatchDay[];
    matchDayMatches:MatchDayMatch[];
    matchDayTeams:MatchDayTeam[];
    teams:Team[];
    saison:Saison;
    userSaisonAlloc:UserSaisonAlloc;
}

export enum MatchEventType {
    GOAL = 0,
    RED_CARD = 1,
    YELLOW_RED_CARD = 2
}

export enum MatchDayState
{
    OPENED = 1,
    CLOSED = 2,
    FINISHED = 3
}