import { Add, Cancel, Edit, Save } from "@mui/icons-material";
import { Alert, Box, Card, Checkbox, Fab, Fade, Grid, MenuItem, Select, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import moment from "moment";
import React, { ChangeEvent, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/common/Loader";
import MatchEventDialogComponent from "../components/matchDay/MatchEventDialogComponent";
import TeamLeagueTableComponent from "../components/saison/TeamLeagueTableComponent";
import HttpService from "../services/HttpService";
import { MatchDayMatchModel, MatchDayState } from "../types/MatchDay";
import { AllocRole, LineUpItem, Team, TeamPlayer, UserSaisonAlloc } from "../types/Saison";
import MatchDayEventsComponent from "../components/matchDay/MatchDayEventsComponent";
import { AppContext } from "../App";
import TeamPlayerStatisticsDialogComponent from "../components/saison/TeamPlayerStatisticsDialogComponent";
import ShirtComponent from "../components/common/ShirtComponent";

const MatchPage = () => {
    
    let { id } = useParams();
    const [matchDayMatchModel, setMatchDayMatchModel] = React.useState<MatchDayMatchModel>(); 
    const [team1Rang, setTeam1Rang] = React.useState(0);
    const [team2Rang, setTeam2Rang] = React.useState(0);
    const [error, setError] = React.useState("");
    const [edit, setEdit] = React.useState(false);
    const [openEventDialog, setOpenEventDialog] = React.useState(false);
    const [reducerValue, forceUpdate] = React.useReducer(x => x + 1, 0);

    const ctx = useContext(AppContext)

    if(matchDayMatchModel) {
        ctx?.setUserSaisonAlloc(matchDayMatchModel.userSaisonAlloc)
        ctx?.setSaison(matchDayMatchModel.saison)
    }

    React.useEffect(function() {

        window.scrollTo(0, 0);

        HttpService.get('/MatchDayAPI/Match', {
            params: { matchDayMatchId: id }
            }).then(res => {
                if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    console.log(res.data);
                }
                setMatchDayMatchModel(res.data);
            });    
    }, [id, reducerValue])

    const navigate = useNavigate();

    function handleMatchDayClick() {
        navigate('/matchday/' + matchDayMatchModel?.matchDay.id)
    }

    const MatchDayTeam1 = matchDayMatchModel?.matchDayMatchTeams.find(s => s.id === matchDayMatchModel.matchDayMatch.matchDayTeamsId1);
    const MatchDayTeam2 = matchDayMatchModel?.matchDayMatchTeams.find(s => s.id === matchDayMatchModel.matchDayMatch.matchDayTeamsId2);

    const Team1 = matchDayMatchModel?.teams.find(s => s.id === MatchDayTeam1?.teamId);
    const Team2 = matchDayMatchModel?.teams.find(s => s.id === MatchDayTeam2?.teamId);

    const Team1Players = matchDayMatchModel?.teamPlayers.filter(s => s.teamId === Team1?.id)
    const Team2Players = matchDayMatchModel?.teamPlayers.filter(s => s.teamId === Team2?.id)

    Team1Players?.sort(function(a, b) {
        if(a?.position !== undefined && b?.position !== undefined) {
            if(a?.position < b?.position) {
                return -1;
            } else {
                return 1
            }
        }
        return 0
    })

    Team2Players?.sort(function(a, b) {
        if(a?.position !== undefined && b?.position !== undefined) {
            if(a?.position < b?.position) {
                return -1;
            } else {
                return 1
            }
        }
        return 0
    })

    const sxProps:SxProps = {
        display: {
            xs: 'none',
            md: 'table-cell'
        } 
    }

    const handleOnLoadTeamLeagueTable = (team1?:number, team2?:number) => {
        if(team1 !== undefined && team2 !== undefined) {
            setTeam1Rang(team1)
            setTeam2Rang(team2)
        }
    }

    const handleEditClick = () => {
        setEdit(true)
    }

    const handleAbortClick = () => {
        setEdit(false);
        forceUpdate();
    }

    const handleNewEventClick = () => {
        setOpenEventDialog(true)
    }

    const handleOnCloseEventDialog = (reload:boolean) => {
        if(reload) {
            forceUpdate();
        }
        setOpenEventDialog(false)
    }

    const handleSaveClick = () => {

        HttpService.post('/MatchDayAPI/Match', {MatchDayTeams : matchDayMatchModel?.matchDayMatchTeams, LineUpItems : matchDayMatchModel?.lineUpItems}).then(res => {

            if(res.status === 200) {
                setEdit(false);
                forceUpdate();
            } else if(res.status === 400) {
                var errorMessage = res.headers["error"];
                if(errorMessage !== undefined) {
                    setError(errorMessage)
                }   
            }
        })
    }

    const handleOnChangeGoalsForTeam1 = (goals:number) => {
        if(MatchDayTeam2?.goalsConceded !== undefined) {
            MatchDayTeam2.goalsConceded = goals;
        }
    }

    const handleOnChangeGoalsForTeam2 = (goals:number) => {
        if(MatchDayTeam1?.goalsConceded !== undefined) {
            MatchDayTeam1.goalsConceded = goals;
        }
    }

    return (
        <div>
            {matchDayMatchModel && (
                <Fade in>
                    <Box>
                    <Alert variant="filled" severity="error" sx={{mb: 1, display: `${error.length === 0 ? 'none' : 'flex'}`}}>
                        {error}
                    </Alert>
                    <Grid container paddingTop='10px'>
                        <Grid item xs={2}/>
                        {/* <Grid item xs={2} textAlign='start' sx={{cursor: 'pointer'}}><ArrowLeft sx={{height: '1.5em', width: '1.5em'}}/></Grid> */}
                        <Grid item xs={8} sx={!edit ? {cursor: 'pointer'} : {}} textAlign='center' marginBottom={'10px'} onClick={!edit ? handleMatchDayClick : undefined}>
                            <Typography fontSize='large'>
                                {matchDayMatchModel.matchDay.number}. SPIELTAG
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{textAlign: 'right'}}>
                            {matchDayMatchModel.userSaisonAlloc.allocRole === AllocRole.owner && (
                                <Box>
                                    {edit && matchDayMatchModel.matchDay.state === MatchDayState.OPENED && (
                                        <Box>
                                            <Fab color="primary" aria-label="add" size="small" onClick={handleSaveClick} sx={{position: 'fixed', bottom: {xs: '116px', md: '66px'}, right: '16px'}}>
                                                <Save />
                                            </Fab>
                                            <Fab color="primary" aria-label="add" size="small" onClick={handleAbortClick} sx={{position: 'fixed', bottom: {xs: '66px', md: '16px'}, right: '16px'}}>
                                                <Cancel />
                                            </Fab>
                                        </Box>
                                    )}
                                    {!edit && matchDayMatchModel.matchDay.state === MatchDayState.OPENED && (
                                        <Box>
                                            <Fab color="primary" aria-label="add" size="small" onClick={handleNewEventClick} sx={{position: 'fixed', bottom: {xs: '116px', md: '66px'}, right: '16px'}}>
                                                <Add />
                                            </Fab>
                                            <Fab color="primary" aria-label="add" size="small" onClick={handleEditClick} sx={{position: 'fixed', bottom: {xs: '66px', md: '16px'}, right: '16px'}}>
                                                <Edit />
                                            </Fab>
                                        </Box>
                                    )}
                                </Box>
                            )} 
                            {/* <Box>
                                <Fab color="primary" aria-label="share" size="small" onClick={() => navigator.share({title: 'Manager-Spiel', url: window.location.href})}>
                                    <ShareOutlined />
                                </Fab>
                            </Box> */}
                        </Grid>
                        <Grid item xs={4} paddingRight='10px' sx={!edit ? {cursor: 'pointer'} : {}} onClick={() => !edit ? navigate('/team/' + matchDayMatchModel.matchDayMatchTeams.find(mdt => mdt.id === matchDayMatchModel.matchDayMatch.matchDayTeamsId1)?.teamId + '/' + encodeURIComponent("match/" + matchDayMatchModel.matchDayMatch.id)) : {}}>
                            <Box marginBottom={'5px'} className='teamlogo-fit' height={'48px'} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + matchDayMatchModel.teams.find(t => t.id === matchDayMatchModel.matchDayMatchTeams.find(mdt => mdt.id === matchDayMatchModel.matchDayMatch.matchDayTeamsId1)?.teamId)?.imageLogoPath + ')'}}>&nbsp;
                            </Box>
                        </Grid>
                        <Grid item xs={4} sx={{textAlign: '-webkit-center'}}>
                            <Box textAlign='center' sx={{
                                    backgroundColor: '#000000', 
                                    borderRadius: '5px 5px 0px 0px',
                                    color: '#CCCCCC',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    maxWidth:'160px'
                                    }}>
                                    <Box sx={{padding: '2px'}}>
                                        {edit && (
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        sx={{ input: { color: '#CCCCCC', textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' } }}
                                                        onChange={e => handleOnChangeGoalsForTeam1(Number(e.target.value))}
                                                        onFocus={e => e.target.select()}
                                                        hiddenLabel
                                                        defaultValue={matchDayMatchModel.matchDayMatchTeams.find(mdt => mdt.id === matchDayMatchModel.matchDayMatch.matchDayTeamsId2)?.goalsConceded}
                                                        variant="filled"
                                                        type="number"
                                                        size="small"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        sx={{ input: { color: '#CCCCCC', textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' } }}
                                                        onChange={e => handleOnChangeGoalsForTeam2(Number(e.target.value))}
                                                        onFocus={e => e.target.select()}
                                                        hiddenLabel
                                                        defaultValue={matchDayMatchModel.matchDayMatchTeams.find(mdt => mdt.id === matchDayMatchModel.matchDayMatch.matchDayTeamsId1)?.goalsConceded}
                                                        variant="filled"
                                                        type="number"
                                                        size="small"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                        {!edit && (
                                            <Grid container>
                                                {moment.duration(moment(matchDayMatchModel.matchDayMatch.matchDate).diff(moment(matchDayMatchModel.serverDateTime.split(".")[0]))).asMinutes() > 0 &&
                                                    moment.duration(moment(matchDayMatchModel.matchDayMatch.matchDate).diff(moment(matchDayMatchModel.serverDateTime.split(".")[0]))).asMinutes() < 60 && (
                                                    <Grid container>
                                                        <Typography fontSize='xx-large' fontWeight='bold' lineHeight='1.5' className="goal-countdown" sx={{width: '100%'}}>
                                                            {Math.ceil(moment.duration(moment(matchDayMatchModel.matchDayMatch.matchDate).diff(moment(matchDayMatchModel.serverDateTime.split(".")[0]))).asMinutes()) + " Min"}
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                {(moment.duration(moment(matchDayMatchModel.matchDayMatch.matchDate).diff(moment(matchDayMatchModel.serverDateTime.split(".")[0]))).asMinutes() < 0 || 
                                                moment.duration(moment(matchDayMatchModel.matchDayMatch.matchDate).diff(moment(matchDayMatchModel.serverDateTime.split(".")[0]))).asMinutes() >= 60) && (
                                                <Grid container>
                                                    <Grid item xs={5}>
                                                        <Typography fontSize='xx-large' fontWeight='bold' lineHeight='1.5' textAlign="right" className={moment(matchDayMatchModel.matchDayMatchTeams.find(mdt => mdt.id === matchDayMatchModel.matchDayMatch.matchDayTeamsId2)?.goalsConcededOldChangedOn).add(5, 'minutes').isAfter(moment(matchDayMatchModel.serverDateTime.split(".")[0])) ? "goal-blink" : ""}>
                                                            {moment(matchDayMatchModel.matchDayMatch.matchDate).isAfter(moment(matchDayMatchModel.serverDateTime.split(".")[0])) ? '-' : matchDayMatchModel.matchDayMatchTeams.find(mdt => mdt.id === matchDayMatchModel.matchDayMatch.matchDayTeamsId2)?.goalsConceded}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography fontSize='xx-large' fontWeight='bold' lineHeight='1.5'>
                                                            :
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Typography fontSize='xx-large' fontWeight='bold' lineHeight='1.5' textAlign="left" className={moment(matchDayMatchModel.matchDayMatchTeams.find(mdt => mdt.id === matchDayMatchModel.matchDayMatch.matchDayTeamsId1)?.goalsConcededOldChangedOn).add(5, 'minutes').isAfter(moment(matchDayMatchModel.serverDateTime.split(".")[0])) ? "goal-blink" : ""}>
                                                            {moment(matchDayMatchModel.matchDayMatch.matchDate).isAfter(moment(matchDayMatchModel.serverDateTime.split(".")[0])) ? '-' : matchDayMatchModel.matchDayMatchTeams.find(mdt => mdt.id === matchDayMatchModel.matchDayMatch.matchDayTeamsId1)?.goalsConceded}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                )}
                                            </Grid>
                                            // <Typography fontSize='xx-large' fontWeight='bold' lineHeight='1.5'>
                                            //     {moment(matchDayMatchModel.matchDayMatch.matchDate) >= moment() ? '- : -' : matchDayMatchModel.matchDayMatchTeams.find(mdt => mdt.id === matchDayMatchModel.matchDayMatch.matchDayTeamsId2)?.goalsConceded + ' : ' + matchDayMatchModel.matchDayMatchTeams.find(mdt => mdt.id === matchDayMatchModel.matchDayMatch.matchDayTeamsId1)?.goalsConceded}
                                            // </Typography>
                                        )}
                                    </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={4} paddingLeft='10px' sx={!edit ? {cursor: 'pointer'} : {}} onClick={() => !edit ? navigate('/team/' + matchDayMatchModel.matchDayMatchTeams.find(mdt => mdt.id === matchDayMatchModel.matchDayMatch.matchDayTeamsId2)?.teamId + '/' + encodeURIComponent("match/" + matchDayMatchModel.matchDayMatch.id)) : {}}>
                            <Box className='teamlogo-fit' marginBottom={'5px'} height={'48px'} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + matchDayMatchModel.teams.find(t => t.id === matchDayMatchModel.matchDayMatchTeams.find(mdt => mdt.id === matchDayMatchModel.matchDayMatch.matchDayTeamsId2)?.teamId)?.imageLogoPath + ')'}}>&nbsp;
                            </Box>
                        </Grid>
                        <Grid item xs={4} paddingRight='10px' sx={!edit ? {cursor: 'pointer'} : {}} onClick={() => !edit ? navigate('/team/' + matchDayMatchModel.matchDayMatchTeams.find(mdt => mdt.id === matchDayMatchModel.matchDayMatch.matchDayTeamsId1)?.teamId + '/' + encodeURIComponent("match/" + matchDayMatchModel.matchDayMatch.id)) : {}}>
                            <Box textAlign='center' >
                                <Typography fontWeight='bold' sx={{fontSize: {md: 'x-large'}}} overflow='hidden' textOverflow={'ellipsis'}>
                                    {matchDayMatchModel.teams.find(t => t.id === matchDayMatchModel.matchDayMatchTeams.find(mdt => mdt.id === matchDayMatchModel.matchDayMatch.matchDayTeamsId1)?.teamId)?.name}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4} sx={{textAlign: '-webkit-center'}}>
                            <Box sx={{backgroundColor: '#cccccc', padding: '5px', maxWidth: '160px', borderRadius: '0px 0px 5px 5px'}}>
                                <Typography fontSize='medium' color='#000'>
                                    {moment(matchDayMatchModel.matchDayMatch.matchDate).format('DD.MM.YYYY')} {moment(matchDayMatchModel.matchDayMatch.matchDate).format('HH:mm')} 
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4} sx={!edit ? {cursor: 'pointer'} : {}} onClick={() => !edit ? navigate('/team/' + matchDayMatchModel.matchDayMatchTeams.find(mdt => mdt.id === matchDayMatchModel.matchDayMatch.matchDayTeamsId2)?.teamId + '/' + encodeURIComponent("match/" + matchDayMatchModel.matchDayMatch.id)) : {}}>
                            <Box textAlign='center' paddingLeft='10px'>
                                <Typography fontWeight='bold' sx={{fontSize: {md: 'x-large'}}} overflow='hidden' textOverflow={'ellipsis'}>
                                    {matchDayMatchModel.teams.find(t => t.id === matchDayMatchModel.matchDayMatchTeams.find(mdt => mdt.id === matchDayMatchModel.matchDayMatch.matchDayTeamsId2)?.teamId)?.name}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4} sx={!edit ? {cursor: 'pointer'} : {}} onClick={() => !edit ? navigate('/team/' + matchDayMatchModel.matchDayMatchTeams.find(mdt => mdt.id === matchDayMatchModel.matchDayMatch.matchDayTeamsId1)?.teamId + '/' + encodeURIComponent("match/" + matchDayMatchModel.matchDayMatch.id)) : {}} paddingRight='10px'>
                            <Box textAlign='center'>
                                {team1Rang > 0 ? (
                                    <Typography sx={{fontSize: 'small'}}>
                                            {team1Rang}. PLATZ
                                    </Typography>
                                ) : (
                                    <Typography sx={{fontSize: 'small'}}>...</Typography>
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4} sx={!edit ? {cursor: 'pointer'} : {}} onClick={() => !edit ? navigate('/team/' + matchDayMatchModel.matchDayMatchTeams.find(mdt => mdt.id === matchDayMatchModel.matchDayMatch.matchDayTeamsId2)?.teamId + '/' + encodeURIComponent("match/" + matchDayMatchModel.matchDayMatch.id)) : {}}>
                            <Box textAlign='center' paddingLeft='10px'>
                                {team2Rang > 0 ? (
                                    <Typography sx={{fontSize: 'small'}}>
                                            {team2Rang}. PLATZ
                                    </Typography>
                                ) : (
                                    <Typography sx={{fontSize: 'small'}}>...</Typography>
                                )}
                            </Box>
                        </Grid>
                    </Grid>


                    <Card sx={{marginTop: '10px'}}>
                        {/* <CardHeader title='Aufstellung'></CardHeader> */}
                        {((Team1Players !== undefined && Team1Players?.length > 0) || (Team2Players !== undefined && Team2Players?.length > 0)) && (
                            <TableContainer sx={{overflowX: "unset", paddingBottom: "10px"}}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>NAME</TableCell>
                                        <TableCell align="center" sx={sxProps}>VEREIN</TableCell>
                                        <TableCell align="right">T</TableCell>
                                        <TableCell align="right">V</TableCell>
                                        <TableCell align="center">KA</TableCell>
                                        <TableCell align="center" sx={edit?undefined:sxProps}>BK</TableCell>
                                        <TableCell align="center" sx={edit?undefined:sxProps}>F</TableCell>
                                        <TableCell align="center" sx={edit?undefined:sxProps}>SP</TableCell>
                                        <TableCell align="center" sx={edit?undefined:sxProps}>TDT</TableCell>
                                        <TableCell align="center" sx={edit?undefined:sxProps}>SPDT</TableCell>
                                        <TableCell align="right" sx={edit?undefined:sxProps}>NOTE</TableCell>
                                        <TableCell sx={{display: {xs: 'none', sm: 'table-cell'}}}>MANAGER</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Team1Players !== undefined && Team1Players?.length > 0 && (
                                    <TableRow sx={{backgroundColor: '#cccccc'}}>
                                        <TableCell colSpan={100}>
                                            <Box className='teamlogo-fit' height={'20px'} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + Team1?.imageLogoPath + ')'}}>&nbsp;
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                    )}
                                    {Team1Players?.map((TeamPlayer) => (
                                        <MatchLineUpItem 
                                            key={TeamPlayer.id}
                                            sx={sxProps}
                                            edit={edit}
                                            LineUpItem={matchDayMatchModel.lineUpItems.find(s => s.teamPlayerId === TeamPlayer.id)}
                                            Team={matchDayMatchModel.teams.find(s => s.id === TeamPlayer.teamId)}
                                            TeamPlayer={TeamPlayer}
                                            UserSaisonAlloc={matchDayMatchModel.userSaisonAllocs.find(s => s.id === TeamPlayer.userSaisonAllocId)} />                                   
                                    ))}
                                    {Team2Players !== undefined && Team2Players?.length > 0 && (
                                    <TableRow sx={{backgroundColor: '#cccccc'}}>
                                        <TableCell colSpan={100}>
                                            <Box className='teamlogo-fit' height={'20px'} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + Team2?.imageLogoPath + ')'}}>&nbsp;
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                    )}
                                    {Team2Players?.map((TeamPlayer) => (
                                        <MatchLineUpItem 
                                            key={TeamPlayer.id}
                                            sx={sxProps}
                                            edit={edit}
                                            LineUpItem={matchDayMatchModel.lineUpItems.find(s => s.teamPlayerId === TeamPlayer.id)}
                                            Team={matchDayMatchModel.teams.find(s => s.id === TeamPlayer.teamId)}
                                            TeamPlayer={TeamPlayer}
                                            UserSaisonAlloc={matchDayMatchModel.userSaisonAllocs.find(s => s.id === TeamPlayer.userSaisonAllocId)} />                                   
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                    </Card>

                    <Card sx={{marginTop: '10px'}}>
                        <TeamLeagueTableComponent
                            Team1Id={Team1?.id}
                            Team2Id={Team2?.id}
                            SaisonId={matchDayMatchModel.matchDay.saisonId} 
                            TillMatchDay={matchDayMatchModel.matchDay.state === MatchDayState.FINISHED ? matchDayMatchModel.matchDay.number : undefined}
                            reducerValue={reducerValue}
                            onLoad={handleOnLoadTeamLeagueTable}></TeamLeagueTableComponent>
                    </Card>

                    <Box sx={{marginTop: '10px'}} >
                        <MatchDayEventsComponent
                            MatchDayEvents={matchDayMatchModel.matchDayMatchEvents}
                            TeamPlayers={matchDayMatchModel.teamPlayers}
                            Teams={matchDayMatchModel.teams}
                            MatchDay={matchDayMatchModel.matchDay}
                            MatchDayMatches={[matchDayMatchModel.matchDayMatch]}
                            MatchDayTeams={matchDayMatchModel.matchDayMatchTeams}
                            LineUpItems={matchDayMatchModel.lineUpItems}
                            UserSaisonAlloc={matchDayMatchModel.userSaisonAlloc}
                        />
                    </Box>


                    <MatchEventDialogComponent
                        open={openEventDialog}
                        lineUpItems={matchDayMatchModel.lineUpItems}
                        matchDayMatch={matchDayMatchModel.matchDayMatch}
                        matchDayMatchTeams={matchDayMatchModel.matchDayMatchTeams}
                        teamPlayers={matchDayMatchModel.teamPlayers}
                        teams={matchDayMatchModel.teams}
                        onClose={handleOnCloseEventDialog}></MatchEventDialogComponent>
                </Box>
                </Fade>
            )}
            {!matchDayMatchModel && (
                <Loader delay={1500}>Spiel wird geladen...</Loader>
            )}
        </div>
    );
}

export default MatchPage

const MatchLineUpItem = (props: {
        TeamPlayer?:TeamPlayer,
        LineUpItem?:LineUpItem,
        UserSaisonAlloc?:UserSaisonAlloc,
        Team?:Team,
        edit:boolean,
        sx?:SxProps
    }) => {

    const [reducerValue, forceUpdate] = React.useReducer(x => x + 1, 0);
    const [openDetailsDialog, setOpenDetailsDialog] = React.useState(false);

    const handleOnChangeGoal = (value:number) => {
        if(props.LineUpItem?.goals !== undefined) {
            props.LineUpItem.goals = value
        }
        forceUpdate();
    }

    const Teams = new Array<Team>();
    if(props.Team)
        Teams.push(props.Team)

    const handleOnChangeAssists = (value:number) => {
        if(props.LineUpItem?.assists !== undefined) {
            props.LineUpItem.assists = value
        }
        forceUpdate();
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>, checked:boolean) => {

        if(event.target.dataset.type === 'substitute') {
            if(props.LineUpItem?.substitute !== undefined) {
                props.LineUpItem.substitute = checked
            }
        }
        if(event.target.dataset.type === 'notPlayed') {
            if(props.LineUpItem?.notPlayed !== undefined) {
                props.LineUpItem.notPlayed = checked
            }
        }
        if(event.target.dataset.type === 'playerOfMatch') {
            if(props.LineUpItem?.playerOfMatch !== undefined) {
                props.LineUpItem.playerOfMatch = checked
            }
        }
        if(event.target.dataset.type === 'teamOfDay') {
            if(props.LineUpItem?.teamOfDay !== undefined) {
                props.LineUpItem.teamOfDay = checked
            }
        }
        if(event.target.dataset.type === 'playerOfDay') {
            if(props.LineUpItem?.playerOfDay !== undefined) {
                props.LineUpItem.playerOfDay = checked
            }
        }

        forceUpdate();
    }

    const handleOnChangeCard = (value:number) => {
        if(props.LineUpItem?.card !== undefined) {
            props.LineUpItem.card = value
        }
        forceUpdate();
    }

    const handleOnChangeGrade = (value:number) => {
        if(props.LineUpItem?.grade !== undefined) {
            props.LineUpItem.grade = value
        }
        forceUpdate();
    }

    const handleOpenTeamPlayerDetails = () => {
        setOpenDetailsDialog(true)
    }

    return (
        <TableRow>
            <TableCell padding={'none'}>
                <ShirtComponent 
                    number={props.TeamPlayer?.number} 
                    teamPlayerPosition={props.TeamPlayer?.position} 
                    // notPlayed={props.LineUpItem?.notPlayed} 
                    // substitude={props.LineUpItem?.substitute} 
                    // teamOfDay={props.LineUpItem?.teamOfDay}
                    // playerOfDay={props.LineUpItem?.playerOfDay}
                    showPosition={true}
                    lineUpItem={props.LineUpItem}
                    />
                {/* <PositionIcon position={props.TeamPlayer?.position}/> */}
            </TableCell>
            <TableCell sx={{cursor: 'pointer'}} onClick={handleOpenTeamPlayerDetails}>
                {props.TeamPlayer?.name}
            </TableCell>
            <TableCell align="center" sx={props.sx}>
                <Box title={props.Team?.name} className='teamlogo-fit' height={'20px'} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/upload/teams/' + props.Team?.imageLogoPath + ')'}}>&nbsp;
                </Box>
            </TableCell>
            <TableCell align="right">
                {props.edit && (
                     <TextField
                        onChange={e => handleOnChangeGoal(Number(e.target.value))}
                        onFocus={e => e.target.select()}
                        hiddenLabel
                        defaultValue={props.LineUpItem?.goals}
                        type="number"
                        size="small"    
                        sx={{minWidth: "2.5em"}}                     
                    />
                )}
                {!props.edit && (
                    props.LineUpItem?.goals
                )} 
            </TableCell>
            <TableCell align="right">
                {props.edit && (
                     <TextField
                        onChange={e => handleOnChangeAssists(Number(e.target.value))}
                        onFocus={e => e.target.select()}
                        hiddenLabel
                        defaultValue={props.LineUpItem?.assists}
                        type="number"
                        size="small"  
                        sx={{minWidth: "2.5em"}}                  
                    />
                )}
                {!props.edit && (
                    props.LineUpItem?.assists
                )} 
            </TableCell>
            <TableCell align="center">
                {props.edit && (
                    <Select size="small" sx={{width: '75px'}} onChange={e => handleOnChangeCard(Number(e.target.value))} value={props.LineUpItem?.card}>
                        <MenuItem value={0}></MenuItem>
                        <MenuItem value={2}>
                            <Box className='teamlogo-fit' width={'20px'} height={'20px'} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/YellowRedCard.png)'}}>&nbsp;
                            </Box>
                        </MenuItem>
                        <MenuItem value={3}>
                            <Box className='teamlogo-fit' width={'20px'} height={'20px'} sx={{backgroundImage:'url(' + HttpService.baseUrl + '/images/RedCard.png)'}}>&nbsp;
                            </Box>
                        </MenuItem>
                    </Select>
                )}
                {!props.edit && props.LineUpItem?.card && (
                    <Box className='teamlogo-fit' height={'20px'} 
                        sx={{backgroundImage:'url(' + HttpService.baseUrl + (props.LineUpItem?.card === 2 ? "/images/YellowRedCard.png)" : "/images/RedCard.png)")}}>&nbsp;
                    </Box>
                )}
            </TableCell>
            <TableCell align="center" sx={props.edit?undefined:props.sx}>
                <Checkbox sx={{padding: '0'}} disabled={!props.edit} checked={props.LineUpItem?.substitute} onChange={handleChange}
                    inputProps={{
                        //@ts-ignore
                        'data-type': 'substitute'
                    }}
                />
            </TableCell>
            <TableCell align="center" sx={props.edit?undefined:props.sx}>
                <Checkbox sx={{padding: '0'}} disabled={!props.edit} checked={props.LineUpItem?.notPlayed} onChange={handleChange}
                    inputProps={{
                        //@ts-ignore
                        'data-type': 'notPlayed'
                    }}
                />
            </TableCell>
            <TableCell align="center" sx={props.edit?undefined:props.sx}>
                <Checkbox sx={{padding: '0'}} disabled={!props.edit} checked={props.LineUpItem?.playerOfMatch} onChange={handleChange}
                    inputProps={{
                        //@ts-ignore
                        'data-type': 'playerOfMatch'
                    }}
                />
            </TableCell>
            <TableCell align="center" sx={props.edit?undefined:props.sx}>
                <Checkbox sx={{padding: '0'}} disabled={!props.edit} checked={props.LineUpItem?.teamOfDay} onChange={handleChange}
                    inputProps={{
                        //@ts-ignore
                        'data-type': 'teamOfDay'
                    }}
                />
            </TableCell>
            <TableCell align="center" sx={props.edit?undefined:props.sx}>
                <Checkbox sx={{padding: '0'}} disabled={!props.edit} checked={props.LineUpItem?.playerOfDay} onChange={handleChange}
                    inputProps={{
                        //@ts-ignore
                        'data-type': 'playerOfDay'
                    }}
                />
            </TableCell>
            <TableCell align="right" sx={props.edit?undefined:props.sx}>
                {/* {props.edit && (
                     <TextField
                        hiddenLabel
                        defaultValue={props.LineUpItem?.grade}
                        type="number"
                        size="small"                       
                    />
                )} */}
                {props.edit && (
                    <Select size="small" sx={{width: '75px'}} onChange={e => handleOnChangeGrade(Number(e.target.value))} value={props.LineUpItem?.grade}>
                        <MenuItem value={0}>0</MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={1.5}>1.5</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={2.5}>2.5</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={3.5}>3.5</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={4.5}>4.5</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={5.5}>5.5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                    </Select>
                )}
                {!props.edit && (
                    props.LineUpItem?.grade
                )} 
            </TableCell>
            <TableCell sx={{display: {xs: 'none', sm: 'table-cell'}}}>
                {props.UserSaisonAlloc?.playerName}
            </TableCell>
            <TeamPlayerStatisticsDialogComponent
                onClose={() => setOpenDetailsDialog(false)}
                open={openDetailsDialog}
                lineUpItem={props.LineUpItem}
                teamPlayerId={props.TeamPlayer?.id} />
        </TableRow>
    )
}